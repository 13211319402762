<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="60%"
    @close="close"
  >
    <el-form
      ref="formRef"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="类型名称" prop="type">
        <el-input v-model="form.type" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { upLoadImg } from '@/api/active'
  import { saveEventType, editEventType, getPage } from '@/api/page'
  import { getAllEvent } from '@/api/global'

  export default defineComponent({
    name: 'pageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        pageList: [],
        form: {},
        queryForm: { name: '', pageNumber: 1, pageSize: 99999 },
        rules: {
          type: [
            { required: true, trigger: 'blur', message: '请输入事件名称' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'
          state.form = Object.assign({}, row)
        }
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            if (state.title == '编辑') {
              let data = await editEventType(state.form)
              msg = data.msg
            } else {
              let data = await saveEventType(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      // 上传海报
      const upLogo = async (e) => {
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.imgUrl = data
      }

      const handleError = (err, file) => {
        proxy.$baseMessage(
          `文件[${file.raw.name}]上传失败,文件大小为${_.round(
            file.raw.size / 1024,
            0
          )}KB`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed1 = (files) => {
        proxy.$baseMessage(
          `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove1 = (file, fileList) => {
        state.form.imgUrl = ''
      }

      const handlePreview = (file) => {
        state.dialogImageUrl = file.url
        console.log(file.url)
        state.dialogVisible = true
      }

      // 获取所有事件
      // const handleGetAllEvent = async () => {
      //   const { data } = await getAllEvent()
      //   state.eventList = data
      // }

      const fetchData = async () => {
        const { data } = await getPage(state.queryForm)
        state.pageList = data.list
      }

      onMounted(() => {
        fetchData()
      })

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
        upLogo,
        handleError,
        handleExceed1,
        handleRemove1,
        handlePreview,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
